import React from "react";
import { Form } from "react-bootstrap";

const SearchBar = ({ searchTerm, handleSearch }) => {
  return (
    <Form.Group controlId="search" className="mb-3">
      <Form.Label>Search by Title or Acronym:</Form.Label>
      <Form.Control
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
      />
    </Form.Group>
  );
};

export default SearchBar;