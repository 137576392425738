import React from "react";
import { Table } from "react-bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css';


const ConferenceTable = ({ data }) => {
  return (
    <Table striped bordered hover responsive>
      <thead className="table-dark">
        <tr>
          <th>Title</th>
          <th>Acronym</th>
          <th>When</th>
          <th>Where</th>
          <th>Submission Deadline</th>
          <th>Notification Due</th>
          <th>Final Version Due</th>
          <th>Link</th>
        </tr>
      </thead>
      <tbody>
        {data.map((conference, index) => (
          <tr key={index}>
            <td>{conference.Title}</td>
            <td>{conference.Acronym}</td>
            <td>{conference.When}</td>
            <td>{conference.Where}</td>
            <td>{conference.SubmissionDeadline || "N/A"}</td>
            <td>{conference.NotificationDue || "N/A"}</td>
            <td>{conference.FinalVersionDue || "N/A"}</td>
            <td>
              <a href={conference.Link} target="_blank" rel="noopener noreferrer">
                <i className="bi bi-link"></i> Visit
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ConferenceTable;