import { Container, Spinner } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import CommonNavbar from './shared/NavBar'; 
import Home from "./pages/Home"; // Assuming you have these components
import NotFound from './pages/NotFound/NotFound';
// import About from "./pages/About";
// import Products from "./pages/Products";

const App = () => {
  return (
    <>
      <CommonNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} /> {/* 404 route */}
      </Routes>
    </>
  );
};

export default App;
